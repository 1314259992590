<template>
  <a-modal v-model="visible" :title="$t('科目列表')" width="1200px" @cancel="close" @ok="onOk">
    <AccountList
      ref="AccountListRef"
      :hasSelected="selectedRows"
      :showSelect="true"
      @select="onSelect"
      @change="onChange"
    />
  </a-modal>
</template>

<script>
import AccountList from '@/view/baseInfo/accountList'
export default {
  props: {
    hasSelected: {
      type: Array,
      default: () => [],
    },
  },
  components: { AccountList },
  data() {
    return {
      visible: false,
      selectedRows: [],
    }
  },
  methods: {
    open() {
      this.selectedRows = this.hasSelected
      this.visible = true
    },
    close() {
      this.visible = false
    },
    onSelect(list) {
      this.selectedRows = list
    },
    onChange(data) {
      let index = this.selectedRows.findIndex((item) => item.code === data.code)
      if (index === -1) {
        this.selectedRows.push(data)
      }
      this.onOk()
    },
    onOk() {
      this.close()
      this.$emit('ok', this.selectedRows)
    },
  },
}
</script>
